function homeSlider(){
  $('.wrap-slider-home').owlCarousel({
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    margin: 0,
    nav: false,
    dots: true,
    lazyLoad: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: {
        items: 1
      }
    }
  });
}
function scrollTop(){
  $('.scroll-top').click(function(e){
    e.preventDefault();
    $('html').animate({'scrollTop':'0'},2000);
  });
}
function numberOffProduct(parentElement){
  // var ch = String.fromCharCode(evt.which);   
  var value = 0;
  $(parentElement).on('input','.number input',function(e){
    var max = $(this).closest('.number').find('input').attr('data-max');
    value = $(parentElement).find('input').val();
    if((/[a-z]/.test(value))){
      // alert('có chữ');
      $(this).val(1);
    }
    if(value<1){
      $(this).val(1);
    }
    if(value > max){
      value = max;
      $(this).closest('.number').find('input').val(value);
    }
  });
  $(parentElement).on('click','.number button.add',function(e){
    var max = $(this).closest('.number').find('input').attr('data-max');
    value = parseInt($(parentElement).find('input').prop('value'));
    value++;
    if(value > max){
      value = max;
    }
    $(this).closest('.number').find('input').val(value);
  });
  $(parentElement).on('click','.number button.rem',function(e){
    value = parseInt($(parentElement).find('input').prop('value'));
    value--;
    $(this).closest('.number').find('input').val(value);
    if(value < 1){
      value = 1;
    }
    $(this).closest('.number').find('input').val(value);
  });  
}
function readMoreButtonContentDes(){
  var trang_thai = true;
  $('.wrap-button-read-more').click(function(){
    if(trang_thai == true){
      $('.wrap-main-tabs .wrap-content').css({'max-height':'unset'});
      $('.wrap-button-read-more button').text('THU NHỎ');
      trang_thai = false;
    }else{
      $('.wrap-main-tabs .wrap-content').css({'max-height':'250px'});
      $('.wrap-button-read-more button').text('XEM THÊM');
      trang_thai = true;
    }
    
  });
}
function services_carousel(){
  $('.services-carousel').owlCarousel({
    loop: false,
    autoplay: true,
    autoplayTimeout: 5000,
    margin: 0,
    nav: false,
    dots: false,
    lazyLoad: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
      1000: {
        items: 4
      }
    }
  });
}
function menu_mobile(){

  //Toggle menu
  $('button.show-hide-menu').click(function(){
    $('.menu-mobile').css({'visibility':'visible','opacity':'1'});
    $('.menu-mobile .wrap-main').css({'transform':'translateX(0)'});
  });
  $('.menu-mobile .wrap-main').click(function(e){
    e.stopPropagation();
  });
  $('.menu-mobile').click(function(e){
    $('.menu-mobile .wrap-main').css({'transform':'translateX(-100%)'});
    var time_out = setTimeout(function(){
      $('.menu-mobile').css({'visibility':'hidden','opacity':'0'});
    },500);
  });

  // Menu item
  $('.menu-mobile .main ul.sub-1').slideUp(0);
  $('.menu-mobile .main li.parent-item').click(function(){
    $(this).find('ul.sub-1').slideToggle(100);
    $(this).find('.icon').toggleClass('rotate-icon');
  });
}
function zoom(){
  $("#zoom_05").elevateZoom({
    easing : true,
    zoomWindowFadeIn: 500,
		zoomWindowFadeOut: 500,
    scrollZoom : true
  });
}
function change_images(){
  $('.slide_product_detail img').click(function(){
    var url = $(this).attr('src');
    $("#zoom_05").attr('src',url);
    $("#zoom_05").data('zoom-image', url).elevateZoom({
      easing : true,
      scrollZoom : true
    });
  });
}
function slide_product_detail(){
  $('.slide_product_detail').owlCarousel({
    loop: false,
    margin: 4,
    nav: true,
    dots: false,
    lazyLoad: true,
    responsive: {
      0: {
        items: 4
      }
    }
  });
}
function tab_product_detail(){
  var is_open = false;
  //Xem đầy đủ
  $('.wrap-read-more button').click(function(){
    if(is_open == false){
      $('.content-descript .main-content').css({'max-height':'unset'});
      $('.wrap-read-more .white-bg').css({'display':'none'});
      $(this).text("Thu gọn");
      is_open = true;
    }else{
      $('.content-descript .main-content').css({'max-height':'500px'});
      $('.wrap-read-more .white-bg').css({'display':'block'});
      $(this).text("Xem đầy đủ");
      is_open = false;
    }
  });

  $('.head-descript-select li').click(function(){
    $('.head-descript-select li').removeClass('active');
    $(this).addClass('active');
    $('.main-content-descript .content').removeClass('active');
    $('.main-content-descript .content:nth-child('+($(this).index()+1)+')').addClass('active');
  });
}
function product_carousel_1(){
  $('.product-carousel-1').owlCarousel({
    loop: false,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    margin: 4,
    nav: false,
    dots: false,
    lazyLoad: true,
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 4
      },
      1000: {
        items: 5
      }
    }
  });
}
function dmsp(){
  // Menu item
  $('.dmsp ul.sub-1').slideUp(0);
  $('.dmsp li.parent-item').click(function(e){
    e.stopPropagation();
    $(this).find('ul.sub-1').slideToggle(100);
    $(this).find('.icon').toggleClass('rotate-icon');
  });

  $('html,body').click(function(){
    $('.dmsp ul.sub-1').slideUp(100);
    $('.dmsp .icon').removeClass('rotate-icon');
  });
}
function set_active_filter_price(){
  $('.dmsp .wrap-input').click(function(){
    if($(this).find('input').prop('checked') == false){
      $(this).find('input').prop('checked',true);
    }else{
      $(this).find('input').prop('checked',false);
    }
  });
  $('.dmsp input').click(function(e){
    e.stopPropagation();
  });
}
function pagination(selector,length){
  var current_page = 1;
  var item = ``;
  for(var i=0; i<length; i++){
    item+=`<li class="d-none"><a href="">${i+1}</a></li>`;
  }
  $(selector).html(item);
  $(selector+' li:nth-child('+current_page+')').addClass('active-mypagination');
  $(selector+'>li').hide(0);
  for(var i=0; i<5; i++){
    $('li:nth-child('+(i+1)+')').removeClass('d-none');
  }

  $(selector+'>li').click(function(event) {
    event.preventDefault();
    current_page = $(this).index()+1;
    $(selector+'>li').removeClass('active-mypagination');
    $(selector+'>li:nth-child('+current_page+')').addClass('active-mypagination');
    if(current_page > 4 && current_page < length){
      $(selector+' li').hide(0);
      for(var i=current_page-2; i<current_page+3; i++){
        $(selector+' li:nth-child('+i+')').show(0).removeClass('d-none');
      }
      $(selector+' li:nth-child(1)').show(0).find('a').text(1);
      $(selector+' li:nth-child('+length+')').show(0).addClass('end').find('a').text(length).removeClass('d-none');
    }else{
      $(selector+' li').hide(0);
      for(var i=0; i<5;i++){
        $(selector+' li:nth-child('+(i+1)+')').show(0);
      }
      $(selector+' li:nth-child(1) a').text(1);
      $(selector+' li:last-child').show(0).removeClass('d-none');
    }
    if($(this).hasClass('end')){
      $(selector+' li').hide(0);
      for(var i=length; i>length-4; i--){
        $(selector+' li:nth-child('+i+')').show(0);
      }
      $(selector+' li:nth-child(1)').show(0);
    }
  });
}
function toggle_filter_mobile(){
  var is_active = false;
  $('.toggle-button-filter').click(function(){
    if(is_active == false){
      $('.mobile-filter').css({
        'transform':'translateX(0)'
      });
      is_active = true;
    }else{
      $('.mobile-filter').css({
        'transform':'translateX(-100%)'
      });
      is_active = false;
    }
  });
}
function validate_account_form(){
  var sign_up = $('.account-form').validate({
    rules:{
      ho_ten: {
        required: true,
        minlength: 5
      },
      so_dien_thoai:{
        required: true,
        minlength: 9,
        digits: true
      },
      email:{
        required: true,
        email:true
      },
      dia_chi_giao:{
        required: true
      },
      mat_khau:{
        required: true,
        minlength: 5
      }
    },
    messages: {
      ho_ten:{
        required: "Vui lòng nhập họ tên",
        minlength: "Tên tối thiểu 5 ký tự"
      },
      so_dien_thoai:{
        required: "Vui lòng nhập số điện thoại",
        minlength: "Tối thiểu 9 ký tự",
        digits: "Vui lòng nhập ký tự [0-9]"
      },
      email:{
        required: "Vui lòng nhập email",
        email:"Vui lòng kiểm tra Email"
      },
      dia_chi_giao:{
        required: "Vui lòng nhập địa chỉ giao hàng cụ thể"
      },
      mat_khau:{
        required: "Vui lòng nhập mật khẩu",
        minlength: "Mật khẩu tối thiểu 5 ký tự"
      }
    }
  });
}
function choose_bank(){
  $('.wrap-all-nh').slideUp(100);
  $('#hinh_thuc_thanh_toan').change(function(){
    if($(this).val() == 1){
      $('.wrap-all-nh').slideUp(200);
    }else{
      $('.wrap-all-nh').slideDown(200);
    }
  });
  $('.wrap-all-nh .item').click(function(){
    $('.wrap-all-nh .item').removeClass('active');
    $(this).addClass('active');
  });
}
function pay_validate(){
  var pay = $('#pay').validate({
    rules:{
      ho_ten: {
        required: true,
        minlength: 5
      },
      so_dien_thoai:{
        required: true,
        minlength: 9,
        digits: true
      },
      email:{
        required: true,
        email:true
      },
      dia_chi_giao:{
        required: true
      }
    },
    messages: {
      ho_ten:{
        required: "Vui lòng nhập họ tên",
        minlength: "Tên tối thiểu 5 ký tự"
      },
      so_dien_thoai:{
        required: "Vui lòng nhập số điện thoại",
        minlength: "Tối thiểu 9 ký tự",
        digits: "Vui lòng nhập ký tự [0-9]"
      },
      email:{
        required: "Vui lòng nhập email",
        email:"Vui lòng kiểm tra Email"
      },
      dia_chi_giao:{
        required: "Vui lòng nhập địa chỉ giao hàng cụ thể"
      }
    }
  });
}
$(document).ready(function () {
  menu_mobile();
  homeSlider();
  //numberOffProduct();
  scrollTop();
  readMoreButtonContentDes();
  services_carousel();
  zoom();
  change_images();
  slide_product_detail();
  tab_product_detail();
  product_carousel_1();
  dmsp();
  set_active_filter_price();
  toggle_filter_mobile();
  validate_account_form();
  choose_bank();
  pay_validate();
});